import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import {
  Grid,
  Box,
  Menu
} from 'fux'

function toMenu (headings) {
  return headings
    .filter(heading => {
      return heading.depth < 4
    })
    .map(heading => {
      return {
        title: heading.value,
        level: '' + heading.depth,
        type: 'url',
        href: '#' + heading.value.toLowerCase().replace(/[^\w]+/g, '-'),
        target: '_self'
      }
    })
}

const PageTemplate = ({ data }) => (
  <Layout>
    <Grid control>
      <Box cols={[0, 12, 3]}>
        <Menu stretch direction='column' sticky data={toMenu(data.markdownRemark.headings)} />
      </Box>
      <Box cols={[12, 12, 6]}>
        <h1>
          {data.markdownRemark.frontmatter.title}
        </h1>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Box>
      <Box cols={[0, 0, 3]} />
    </Grid>
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
      headings {
        value
        depth
      }
    }
  }
`
